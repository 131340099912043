/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// React icons
import { BsCheckCircleFill } from "react-icons/bs";

// Vision UI Dashboard React example components
import TimelineItem from "examples/Timeline/TimelineItem";

// Vision UI Dashboard theme imports
import { useContext, useEffect, useState } from "react";
import { DataContext } from "utils/DataContext";
import { callAPI } from "api";
import { API_CALLS } from "api";
import VuiAvatar from "components/VuiAvatar";
import avatar1 from "assets/images/avatar1.png";
import avatar2 from "assets/images/avatar2.png";
import avatar3 from "assets/images/avatar3.png";
import avatar4 from "assets/images/avatar4.png";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";

function OrdersOverview() {
  const [fanData, setFanData] = useState();
  const { gradients } = colors;
  const {
    notify,
  } = useContext(DataContext);

  const avatars = (members) =>
    members.map(([image, name]) => (
      <VuiAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { dark } }) =>
              `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -2,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
  ));

  const fetchCommunity = async id => {
    if(localStorage.getItem('id')) {
      try {
        const response = await callAPI(API_CALLS.GET_TOP_LOCATIONS, '');
        setFanData(response.fanData.fans);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          window.location.href = '/authentication/sign-in';
        } else notify('Error in loading data', 'error');
      }
    } else window.location.href = '/authentication/sign-in';
  };

  useEffect(() => {
    fetchCommunity();
  }, []);

  return (
    <Card style={{ height: '100%', background: '#fff', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'}}>
      <VuiBox mb="16px">
        <VuiTypography variant="lg" fontWeight="bold" mb="5px" color="balcktxt">
          Top Locations
        </VuiTypography>
        <VuiBox mb={2}>
          <VuiBox display="flex" alignItems="center">
            <BsCheckCircleFill color="green" size="15px" mr="5px" />
            <VuiTypography variant="button" color="text" fontWeight="medium" ml="5px" mr="2px">
              Your Top
            </VuiTypography>{" "}
            <VuiTypography variant="button" color="text" fontWeight="regular">
              {" "}
              cities
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
      <VuiBox>
        {
          fanData ? Object.keys(fanData).map(key => <TimelineItem
            icon={<VuiBox display="flex" py={1}>
            {avatars([
              [avatar1, "Ryan Tompson"],
              [avatar2, "Romina Hadid"],
              [avatar3, "Alexander Smith"],
              [avatar4, "Jessica Doe"],
            ])}
          </VuiBox>}
            title={key}
            dateTime={`${fanData[key].length} Fans`}
          />) : <></>
          }
      </VuiBox>
    </Card>
  );
}

export default OrdersOverview;
