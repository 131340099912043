/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import Grid from "@mui/material/Grid";
import Skeleton from '@mui/material/Skeleton';

import { capitalize, Card, Stack } from "@mui/material";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";
import ReferralTracking from "layouts/dashboard/components/ReferralTracking";

// React icons
import { IoFemale, IoGlobe, IoMale, IoTransgender } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaInstagram, FaMusic, FaShoppingCart, FaSpotify, FaYoutube } from "react-icons/fa";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import PieChart from "examples/Charts/PieChart/PieChart";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
import { useContext, useEffect, useState } from "react";
import { callAPI } from "api";
import { API_CALLS } from "api";
import { DataContext } from "utils/DataContext";
import { pieChartOptionsDashboard } from "./data/pieChartOptions";

function Dashboard() {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [analytics, setAnalytics] = useState();
  const [genderData, setGenderData] = useState();
  const [monthData, setMonthData] = useState();

  const {
    notify,
  } = useContext(DataContext);
  
  const fetchAnalytics = async () => {
    try {
      const response = await callAPI(API_CALLS.GET_ANALYTICS, '');
      setAnalytics(response);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        window.location.href = '/authentication/sign-in';
      } else notify('Error in loading data', 'error');
    }
  };

  const fetchGenderData = async () => {
    try {
      const response = await callAPI(API_CALLS.GET_GENDERS, '');
      setGenderData(response.fanData.fans);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        window.location.href = '/authentication/sign-in';
      } else notify('Error in loading data', 'error');
    }
  }; 
  
  const fetchMonthData = async () => {
    try {
      const response = await callAPI(API_CALLS.GET_MONTHS, '');
      setMonthData(response.fanData);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        window.location.href = '/authentication/sign-in';
      } else notify('Error in loading data', 'error');
    }
  }; 

  useEffect(() => {
    fetchAnalytics();
    fetchGenderData();
    fetchMonthData();
  }, []);

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return (
    <DashboardLayout>
      <DashboardNavbar avatar={localStorage.getItem('avatar')} />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={5}>
              <WelcomeMark />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <SatisfactionRate engagement={ analytics ? analytics.engagement : 0 } engagementTxt={ analytics ? analytics.engagementTxt : `0 %` } />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <ReferralTracking />
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "total revenue", fontWeight: "regular" }}
                count={analytics ? analytics.revenueTxt : <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />}
                icon={{ color: "white", component: <IoWallet size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "total members" }}
                count={analytics ? analytics.numMembersTxt : <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />}
                // percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "info", component: <IoGlobe size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "total purchases" }}
                count={analytics ? analytics.numPurchasesTxt : <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />}
                // percentage={{ color: "error", text: "-2%" }}
                icon={{ color: "info", component: <FaShoppingCart size="20px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "total streams" }}
                count={analytics ? analytics.numStreams : <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{ color: "info", component: <IoDocumentText size="22px" color="white" /> }}
              />
            </Grid>

            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "instagram", fontWeight: "regular" }}
                count={analytics ? analytics.numInstaTxt : <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />}
                icon={{ color: "info", component: <FaInstagram size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "spotify" }}
                count={analytics ? analytics.numSpotifyTxt : <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />}
                // percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "info", component: <FaSpotify size="20px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "youtube" }}
                count={analytics ? analytics.numYtTxt : <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />}
                // percentage={{ color: "error", text: "-2%" }}
                icon={{ color: "info", component: <FaYoutube size="20px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "apple music" }}
                count={analytics ? analytics.numAppleTxt : <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{ color: "info", component: <FaMusic size="20px" color="white" /> }}
              />
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} xl={7}>
              <Card sx={{
                background: '#fff', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'
              }}>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="blacktxt" fontWeight="bold" mb="5px">
                    Engagement Overview
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      Fan acquisition {' '}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        and streams
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ height: "310px" }}>
                    {monthData ? <LineChart
                      lineChartData={[{ name: 'Members', data: Object.keys(monthData.fans).sort(function (a, b) { 
                        return months.indexOf(a.substr(0, 3)) - months.indexOf(b.substr(0, 3)) 
                      }).map(key => monthData.fans[key])}, { name: 'Streams', data: monthData.streams ? Object.keys(monthData.streams).sort(function (a, b) { 
                        return months.indexOf(a.substr(0, 3)) - months.indexOf(b.substr(0, 3)) 
                      }).map(key => monthData.streams[key]) : []}]}
                      lineChartOptions={lineChartOptionsDashboard}
                    /> : <></>}
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={5}>
              <Card sx={{
                background: '#fff', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'
              }}>
                <VuiBox>
                  <VuiBox
                    mb="24px"
                    height="220px"
                    sx={{
                      background: 'rgb(220, 20, 60)',
                      borderRadius: "20px",
                    }}
                  >
                    {genderData ? <PieChart
                      barChartData={genderData ? Object.keys(genderData).map(key => genderData[key]) : []}
                      barChartOptions={pieChartOptionsDashboard}
                    /> : <></>}
                  </VuiBox>
                  <VuiTypography variant="lg" color="blacktxt" fontWeight="bold" mb="5px">
                    Fan Diversity
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        Overview across genders
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <Grid container spacing="50px">
                    {genderData ? 
                      Object.keys(genderData).map(key => 
                        <Grid item xs={6} md={3} lg={4}>
                          <Stack
                            direction="row"
                            spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                            mb="6px"
                          >
                            <VuiBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ borderRadius: "6px", width: "25px", height: "25px", background: key === 'male' ? '#7CB9E8' : key === 'female' ? '#318CE7' : '#89CFF0' }}
                            >
                              {key === 'male' ? <IoMale color="#fff" size="12px" /> : key === 'female' ? <IoFemale color="#fff" size="12px" /> : <IoTransgender color="#fff" size="12px" />}
                            </VuiBox>
                            <VuiTypography color="text" variant="button" fontWeight="medium">
                              {capitalize(key)}
                            </VuiTypography>
                          </Stack>
                          <VuiTypography color="blacktxt" variant="lg" fontWeight="bold" mb="8px">
                            {`${genderData[key]} %`}
                          </VuiTypography>
                           <VuiProgress value={genderData[key]} color="brand" sx={{ background: "rgba(0, 0, 0, 0.2)" }} />
                        </Grid>
                      ) : <></>
                    }
                  </Grid>
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
