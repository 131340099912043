// login.ts

import {
  setAuthTokens,
  ROUTES,
  setProfileData,
  getRefreshToken,
} from 'api/http-common';

import { axiosInstance } from './api';

export const loginGoogle = async (params) => {
  const response = await axiosInstance.post(ROUTES.LOGIN_GOOGLE, {
    ...params,
  });

  // save tokens to storage
  await setAuthTokens({
    accessToken: response.data.tokens.access.token,
    refreshToken: response.data.tokens.refresh.token,
  });

  await setProfileData(response.data.user);
};

export const loginApple = async (params) => {
  const response = await axiosInstance.post(ROUTES.LOGIN_APPLE, {
    ...params,
  });
  // save tokens to storage
  await setAuthTokens({
    accessToken: response.data.tokens.access.token,
    refreshToken: response.data.tokens.refresh.token,
  });
  await setProfileData(response.data.user);
};

export const logout = async () => {
  const refreshToken = await getRefreshToken();
  localStorage.clear();
  await axiosInstance.post(ROUTES.LOGOUT, { refreshToken });
};
