/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components


// Vision UI Dashboard React components

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {Editor, EditorProvider, Toolbar} from 'react-simple-wysiwyg';

// Vision UI Dashboard React base styles

// Dashboard layout components

// React icons

// Data
import { useContext, useRef, useState } from "react";
import { DataContext } from "utils/DataContext";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import { IoArrowBackCircle, IoArrowForward, IoArrowForwardCircle, IoRefreshCircleSharp, IoRemove, IoSend } from "react-icons/io5";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { getEmailContent } from "api/users";
import { CircularProgress, Divider, Icon, Tooltip } from "@mui/material";
import sanitize from "sanitize-html";
import { Delete } from "@mui/icons-material";
import { IoIosCloseCircle, IoIosSend } from "react-icons/io";
import { FaArrowCircleRight } from "react-icons/fa";
import VuiBadge from "components/VuiBadge";

const VIEWS = { CHAT: 'chat', TEMPLATE: 'template' };

function EmailSender() {
  const {
    notify,
  } = useContext(DataContext);

  const [prompt, setPrompt] = useState();
  const [loader, setLoader] = useState(false);
  const [sending, setSending] = useState();
  const [currentView, setCurrentView] = useState(VIEWS.CHAT);
  const [template, setTemplate] = useState();
  const ref = useRef();

  const sendEmail = () => {
    setSending(true);
    setTimeout(() => {
        setSending(false);
        setCurrentView(VIEWS.CHAT);
        setTemplate();
        setPrompt();
        notify('Email sent to everyone successfully!', 'success');
    }, 5000);
  };

  const generateEmailTemplate = async (val) => {
    try {
        let query = { text: val ? val : prompt }
        const response = await getEmailContent(query);
        setTemplate(response.html);
        setCurrentView(VIEWS.TEMPLATE);
        setLoader(false);
      } catch (err) {
        setLoader(false);

        if (err.response && err.response.status === 401) {
          window.location.href = '/authentication/sign-in';
        } else notify('There is some issue', 'error');
      }
  };

  const onChangeEmail = (html) => {
    setTemplate(html);
  };

  const handleEnterClick = async () => {
    if(prompt) {
        setLoader(true);
        setCurrentView(VIEWS.CHAT);
        await generateEmailTemplate(prompt);
    }
  }

  const handlePromptClick = async (val) => {
    if(val) {
        setLoader(true);
        setPrompt(val);
        await generateEmailTemplate(val);
    }
  }

  return (
    <DashboardLayout>
        <DashboardNavbar avatar={localStorage.getItem('avatar')} />
        <VuiBox py={3} >
        {
                currentView === VIEWS.CHAT ? 
                <VuiBox right={0} top={0} left="15%" height="100%" position="fixed" display="flex" justifyContent="center" alignItems='center'>
                    {!loader ? <VuiBox display="flex" flexDirection="column" alignItems="center" gap="40px" width="880px">
                        <VuiBadge badgeContent="Beta" color="brand">
                            <VuiTypography variant="h4" color="blacktxt" fontWeight="bold">
                                What do you want to say to your fans today?
                            </VuiTypography>
                        </VuiBadge>
                        <VuiInput
                            placeholder="Enter any topic or subject here..."
                            size="large"
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            icon={{ component: 
                                <VuiBox
                                    bgColor={prompt ? "#141414" : "#a6a6a6"}
                                    color="#141414"
                                    width="2rem"
                                    height="2rem"
                                    borderRadius="25%"
                                    display="flex"
                                    onClick={() => handleEnterClick()}
                                    sx={{ cursor: prompt ? "pointer" : "default" }}
                                    justifyContent="center"
                                    alignItems="center"
                                    shadow="md"
                                >
                                    <IoArrowForward size="16px" color="#fff" />
                                </VuiBox>,
                                direction: 'right' 
                            }}
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("sm")]: {
                                    maxWidth: "880px",
                                },
                                [breakpoints.only("sm")]: {
                                    maxWidth: "880px",
                                },
                                width: '880px',
                                backgroundColor: "info.main !important",
                            })}
                        />
                        <VuiBox display="flex" justifyContent="space-between" alignItems="center" gap="10px">
                            <VuiButton
                                circular={true}
                                variant="outlined"
                                size="small"
                                sx={{ fontWeight: "normal" }}
                                color={'dark'}
                                onClick={() => handlePromptClick('Welcome email for my community')}
                                >
                                Welcome email for my community
                            </VuiButton>
                            <VuiButton
                                circular={true}
                                variant="outlined"
                                size="small"
                                color={'dark'}
                                onClick={() => handlePromptClick('Artist is going to announce an Exclusive content for their community')}
                                sx={{ fontWeight: "normal" }}
                                >
                                Exclusive content announcement
                            </VuiButton>
                            <VuiButton
                                circular={true}
                                variant="outlined"
                                size="small"
                                color={'dark'}
                                onClick={() => handlePromptClick('Artist is going to announce the top 3 winners of the last competition')}
                                sx={{ fontWeight: "normal" }}
                                >
                                Announce top 3 winners
                            </VuiButton>                       
                        </VuiBox>
                    </VuiBox> : 
                    <VuiBox display="flex" flexDirection="column" alignItems="center" gap="10px">
                        <CircularProgress color="brand" variant="indeterminate" size={40} />
                        <VuiTypography variant="h6" color="blacktxt" fontWeight={500}>
                            Writing email
                        </VuiTypography>
                    </VuiBox>}
                </VuiBox> : 
                <VuiBox overflowY="scroll" right={0} top={0} left="15%" height="100%" position="fixed" display="flex" justifyContent="center" alignItems='center'>
                    {!sending ? <VuiBox>
                        <EditorProvider>
                            <Editor value={template.replaceAll("^\"|\"$", "").replaceAll('`', '')} onChange={(e) => onChangeEmail(e.target.value)} style={{ maxWidth: "480px", background: 'white', border: 0}}>
                                <Toolbar style={{display: 'flex', height: 60, background: 'white', justifyContent: 'space-between'}}>
                                <Tooltip key={'close'} title={'Close'} placeholder="bottom">
                                    <VuiButton onClick={() => {
                                        setTemplate();
                                        setCurrentView(VIEWS.CHAT);
                                    }} variant="text" color="dark">
                                        <IoIosCloseCircle size={36} />
                                    </VuiButton>
                                    </Tooltip>
                                    <VuiBox display="flex" alignItems="center" gap="0">
                                        <Tooltip key={'refresh'} title={'Rewrite'} placeholder="bottom">
                                            <VuiButton onClick={() => handleEnterClick()} variant="text" color="dark">
                                                <IoRefreshCircleSharp size={36} />
                                            </VuiButton>
                                        </Tooltip>
                                    </VuiBox>
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                        <VuiBox mt={2} mb={1}>
                            <VuiButton color="brand" fullWidth onClick={() => sendEmail(true)}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    SEND EMAIL<IoArrowForwardCircle size={34} />
                                </div>
                            </VuiButton>
                        </VuiBox>
                        </VuiBox> : 
                        <VuiBox display="flex" flexDirection="column" alignItems="center" gap="10px">
                        <CircularProgress color="brand" variant="indeterminate" size={40} />
                        <VuiTypography variant="h6" color="blacktxt" fontWeight={500}>
                                Sending emails to fans
                            </VuiTypography>
                        </VuiBox>
                    }
                </VuiBox>
            }                
        </VuiBox>
        <Footer />
    </DashboardLayout>
  );
}

export default EmailSender;
