/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useContext, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";
import { signInWithGoogle } from "utils/firebase";
import { setAuthTokens } from "api/http-common";
import { CircularProgress, Divider, useColorScheme } from "@mui/material";
import axios from "axios";
import colors from "assets/theme/base/colors";
import { callAPI } from "api";
import { DataContext } from "utils/DataContext";
import BasicLayout from "../components/BasicLayout";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const { brand } = colors;
  const {
    notify,
  } = useContext(DataContext);
  
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [loading, setLoading] = useState('');

  const singupWithGoogle = (profile = {}) => {
    axios({
      method: 'post',
      url: 'https://api.fanfliq.com/v1/auth/login/google',
      data: { ...profile },
    })
      .then(async function (response) {
        setLoading('');

        setAuthTokens({
          accessToken: response.data.tokens.access.token,
          refreshToken: response.data.tokens.refresh.token,
        });

        localStorage.setItem('name', response.data.user.name);
        localStorage.setItem('id', response.data.user._id);
        localStorage.setItem('avatar', response.data.user.avatar);

        let data = {};
        data.id = localStorage.getItem('id');
        data.name = localStorage.getItem('name');
        data.avatar = localStorage.getItem('avatar');

        window.location.href = '/dashboard';
      })
      .catch(function (e) {
        console.log(e);
        //handle error
        setLoading('');
        notify('Error Logging In', 'error');
      });
  };

  const getResult = async result => {
    const queryParams = new URLSearchParams(location.search);

    if (result && result.user) {
      setLoading('google');
      const profile = {
        type: 'google',
        webLink: queryParams.get('redirect') ? queryParams.get('redirect') : '',
        profile: {
          photos: result.user.photoURL,
          name: result.user.displayName,
          email: result.user.email,
        },
        accessToken: result._tokenResponse.oauthIdToken,
      };

      singupWithGoogle(profile);
    } else {
      setLoading('');
    }
  };

  const responseGoogle = () => {
    setLoading('google');
    signInWithGoogle().then(result => {
      getResult(result);
    });
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handelEMailSignIn = () => {
    if(email && password) {
      setLoading('email');
      axios({
        method: 'post',
        url: 'https://api.fanfliq.com/v1/auth/login/email',
        data: { email: email, password: password },
      })
        .then(async function (response) {
          setLoading('');
  
          setAuthTokens({
            accessToken: response.data.tokens.access.token,
            refreshToken: response.data.tokens.refresh.token,
          });
  
          localStorage.setItem('name', response.data.user.name);
          localStorage.setItem('id', response.data.user._id);
          localStorage.setItem('avatar', response.data.user.avatar);
  
          let data = {};
          data.id = localStorage.getItem('id');
          data.name = localStorage.getItem('name');
          data.avatar = localStorage.getItem('avatar');
  
          window.location.href = '/dashboard';
        })
        .catch(function (e) {
          console.log(e);
          //handle error
          setLoading('');
          notify('Error Logging In', 'error');
        });
    }
  };

  return (
    <BasicLayout
      title="Welcome to FanFliQ!"
      color="white"
      description="Enter your email and password to sign in"
      premotto="BUILD, ENGAGE, MOENTIZE:"
      motto="FanFliQ"
      image={bgSignIn}
    >
      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email" placeholder="Your email..." sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })} />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="Your password..."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Remember me
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
          <VuiButton color="brand" onClick={() => handelEMailSignIn()} fullWidth>
           {loading === 'email' ? <CircularProgress size={16} /> : 'SIGN IN'}
          </VuiButton>
        </VuiBox>
        <Divider light />
        <VuiBox mt={2} mb={1}>
          <VuiButton color="light" fullWidth onClick={responseGoogle}>
            {loading === 'google' ? <CircularProgress size={16} /> : <div style={{ display: 'flex', alignItems: 'center' }}>
              <GoogleIcon color="blue" style={{ marginRight: '4px' }} />SIGN IN WITH GOOGLE
            </div>}
          </VuiButton>
        </VuiBox>
        <VuiBox mt={10} textAlign="center">
          {/* <VuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign up
            </VuiTypography>
          </VuiTypography> */}
        </VuiBox>
      </VuiBox>
    </BasicLayout>
  );
}

export default SignIn;
