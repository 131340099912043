import "core-js/stable/atob"
import axios from "axios"
import { jwtDecode } from "jwt-decode"

const EXPIRE_FUDGE = 10

export const STORAGE_KEY = `auth-tokens`
export const STORAGE_KEY_PROFILE = `profile`
export const TRACK_STREAM_KEY = `streams`
export const FAN_SETUP_STEP = `fan-step`

export const BASE_URL = {
  TEST: "http://localhost:8001/v1",
  PROD: "https://api.fanfliq.com/v1"
}

export const ROUTES = {
  REFRESH_TOKEN: "/auth/refresh-tokens",
  LOGIN_GOOGLE: "/auth/login/google",
  LOGIN_APPLE: "/auth/login/google",
  LOGOUT: "/auth/logout",
  PROFILE: "/users/{userId}",
  PROFILE_BY_USERNAME: `${BASE_URL.PROD}/users/details/{username}`,

  USERS: "/users?{filters}",
  DELETE: "/users/delete/entry",

  AVATAR_UPLOAD: "/users/avatar/upload/url",

  BLOG: "/blogs/{blogId}",
  BLOGS: "/blogs?{filters}",

  CONTENTS: "/content?{filters}",
  CONTENT: "/content/{contentId}",
  CONTENT_UPLOAD: "/content/upload/url?{filters}",
  CONTENT_SHARE: "/content/sharable/{contentId}",

  ANALYTICS: "/users/all/analytics?{filters}",
  COMMUNITY: "/users/all/community?{filters}",
  TOP_LOCATIONS: "/users/top/locations?{filters}",
  GET_GENDERS: "/users/all/genders?{filters}",
  GET_MONTHS: "/users/all/months?{filters}",
  GET_EMAIL_CONTENT: "/users/write/email",
  PLANS_API: "/plans?{filters}",
  CREATE_PLANS_API: "/plans",
  UPDATE_PLAN: "/plans/{planId}",
  GET_PLAYER_KEY: "/users/player/token",

  GET_SUBS_DETAILS: "/subscription?{filters}",
  CANCEL_SUBS: "/subscription/cancel",

  REPORT: "/report",
  TRACK_STREAM: "/stream",

  GET_NOTIFICATIONS: "/notification?{filters}"
}

export const fetchImage = async uri => {
  const response = await fetch(uri)
  const blob = await response.blob()
  return blob
}

/**
 * Checks if refresh tokens are stored
 * @async
 * @returns {Promise<boolean>} Whether the user is logged in or not
 */
export const isLoggedIn = async () => {
  const token = await getRefreshToken()
  return !!token
}

/**
 * Get user id
 * @async
 * @returns {Promise<user>} Whether the user is logged in or not
 */
export const getUserId = () => {
  const user = localStorage.getItem(STORAGE_KEY_PROFILE)
  if (!user) return

  try {
    // parse stored tokens JSON
    return JSON.parse(user).id
  } catch (error) {
    throw new Error(`Failed to parse user: ${user}`)
  }
}

/**
 * Get user id
 * @async
 * @returns {Promise<user>} Whether the user is logged in or not
 */
export const getIsVerified = () => {
  const user = localStorage.getItem(STORAGE_KEY_PROFILE)
  if (!user) return

  try {
    // parse stored tokens JSON
    return JSON.parse(user).isVerified
  } catch (error) {
    throw new Error(`Failed to parse user: ${user}`)
  }
}

/**
 * Get fan setup state
 * @async
 * @returns {Promise<user>} Whether the fan has done setup or not
 */
export const isFanSetupDone = async () => {
  const isSetupDone = localStorage.getItem(FAN_SETUP_STEP)
  if (!isSetupDone) return

  try {
    // parse stored tokens JSON
    return JSON.parse(isSetupDone)
  } catch (error) {
    throw new Error(`Failed to parse state: ${isSetupDone}`)
  }
}

/**
 * Sets the access and refresh tokens
 * @async
 * @param {AuthTokens} tokens - Access and Refresh tokens
 * @returns {Promise}
 */
export const setAuthTokens = tokens =>
  localStorage.setItem(STORAGE_KEY, JSON.stringify(tokens))

/**
 * Sets the fan setup state
 * @async
 * @returns {Promise}
 */
export const setIsFanSetupDone = () =>
  localStorage.setItem(FAN_SETUP_STEP, JSON.stringify(true))

/**
 * Sets the user personal data
 * @async
 * @param {AuthTokens} user - User personal data
 * @returns {Promise}
 */
export const setProfileData = user =>
  localStorage.setItem(STORAGE_KEY_PROFILE, JSON.stringify(user))

/**import * as FileSystem from 'expo-file-system';

 * Sets the streams data
 * @async
 * @param {AuthTokens} contentId - Content being streamed
 * @returns {Promise}
 */
export const setStreamsLocal = async contentId => {
  let previous = await getStreamsLocal()
  if (previous && previous.length > 0) {
    if (previous.length === 10) previous.shift()
    previous.push(contentId)
  } else previous = [contentId]
  localStorage.setItem(TRACK_STREAM_KEY, JSON.stringify(previous))
}

/**
 * Sets the access token
 * @async
 * @param {Promise} token - Access token
 */
export const setAccessToken = token => {
  const tokens = getAuthTokens()
  if (!tokens) {
    throw new Error(
      "Unable to update access token since there are not tokens currently stored"
    )
  }

  tokens.accessToken = token
  return setAuthTokens(tokens)
}

/**
 * Clears both tokens
 * @async
 * @returns {Promise}
 */
export const clearAuthTokens = () => localStorage.removeItem(STORAGE_KEY)

/**
 * Clears user profile data
 * @async
 * @returns {Promise}
 */
export const clearProfileData = () =>
  localStorage.removeItem(STORAGE_KEY_PROFILE)

/**
 * Returns the stored refresh token
 * @async
 * @returns {Promise<string>} Refresh token
 */
export const getRefreshToken = () => {
  const tokens = getAuthTokens()
  return tokens ? tokens.refreshToken : undefined
}

/**
 * Returns the stored access token
 * @async
 * @returns {Promise<string>} Access token
 */
export const getAccessToken = () => {
  const tokens = getAuthTokens()
  return tokens ? tokens.accessToken : undefined
}

/**
 * @callback requestRefresh
 * @param {string} refreshToken - Token that is sent to the backend
 * @returns {Promise} Promise that resolves an access token
 */

/**
 * Gets the current access token, exchanges it with a new one if it's expired and then returns the token.
 * @async
 * @param {requestRefresh} requestRefresh - Function that is used to get a new access token
 * @returns {Promise<string>} Access token
 */
export const refreshTokenIfNeeded = async requestRefresh => {
  // use access token (if we have it)
  let accessToken = await getAccessToken()

  // check if access token is expired
  if (!accessToken || isTokenExpired(accessToken)) {
    // do refresh
    accessToken = await refreshToken(requestRefresh)
  }

  return accessToken
}

/**
 *
 * @param {axios} axios - Axios instance to apply the interceptor to
 * @param {AuthTokenInterceptorConfig} config - Configuration for the interceptor
 */
export const applyAuthTokenInterceptor = (axios, config) => {
  if (!axios.interceptors) throw new Error(`invalid axios instance: ${axios}`)

  axios.interceptors.request.use(authTokenInterceptor(config))
}

// PRIVATE

/**
 *  Returns the refresh and access tokens
 * @async
 * @returns {AuthTokens} Object containing refresh and access tokens
 */
const getAuthTokens = () => {
  const rawTokens = localStorage.getItem(STORAGE_KEY)
  if (!rawTokens) return

  try {
    // parse stored tokens JSON
    return JSON.parse(rawTokens)
  } catch (error) {
    throw new Error(`Failed to parse auth tokens: ${rawTokens}`)
  }
}

/**
 *  Returns the locally stored streamed content ids
 * @async
 * @returns {Promise<Array<string>} array containing ids of streamed content
 */
export const getStreamsLocal = async () => {
  const rawIds = localStorage.getItem(TRACK_STREAM_KEY)
  if (!rawIds) return

  try {
    // parse stored ids JSON
    return JSON.parse(rawIds)
  } catch (error) {
    throw new Error(`Failed to parse ids tokens: ${rawIds}`)
  }
}

export const removeAllLocals = async () => {
  localStorage.removeItem(TRACK_STREAM_KEY)
}

/**
 *  Returns the user personal data
 * @async
 * @returns {Promise<user>} Object containing user data
 */
export const getProfileData = async () => {
  const profileString = localStorage.getItem(STORAGE_KEY_PROFILE)
  if (!profileString) return

  try {
    // parse stored tokens JSON
    return JSON.parse(profileString)
  } catch (error) {
    throw new Error(`Failed to parse user data: ${profileString}`)
  }
}

export const updateTypeProfile = async (type = "") => {
  const prof = await getProfileData()
  if (prof) prof.isVerified = type === "artist"
  if (prof) await setProfileData(prof)
}

/**
 * Checks if the token is undefined, has expired or is about to expire
 *
 * @param {string} token - Access token
 * @returns {boolean} Whether or not the token is undefined, has expired or is about to expire
 */
const isTokenExpired = token => {
  if (!token) return true
  const expiresIn = getExpiresIn(token)
  return !expiresIn || expiresIn <= EXPIRE_FUDGE
}

/**
 * Gets the unix timestamp from the JWT access token
 *
 * @param {string} token - Access token
 * @returns {string} Unix timestamp
 */
const getTimestampFromToken = token => {
  const decoded = jwtDecode(token)

  return decoded.exp
}

/**
 * Returns the number of seconds before the access token expires or -1 if it already has
 *
 * @param {string} token - Access token
 * @returns {number} Number of seconds before the access token expires
 */
const getExpiresIn = token => {
  const expiration = getTimestampFromToken(token)

  if (!expiration) return -1

  return expiration - Date.now() / 1000
}

/**
 * Refreshes the access token using the provided function
 * @async
 * @param {requestRefresh} requestRefresh - Function that is used to get a new access token
 * @returns {Promise<string>} - Fresh access token
 */
const refreshToken = async requestRefresh => {
  const refreshToken = await getRefreshToken()
  if (!refreshToken) throw new Error("No refresh token available")

  try {
    // Refresh and store access token using the supplied refresh function
    const newTokens = await requestRefresh(refreshToken)
    if (typeof newTokens === "object" && newTokens?.accessToken) {
      setAuthTokens(newTokens)
      return newTokens.accessToken
    } else if (typeof newTokens === "string") {
      setAccessToken(newTokens)
      return newTokens
    }
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error

    // Failed to refresh token
    const status = error.response?.status
    if (status === 401 || status === 422) {
      // The refresh token is invalid so remove the stored tokens
      localStorage.removeItem(STORAGE_KEY)
      error.message = `Got ${status} on token refresh; clearing both auth tokens`
    }

    throw error
  }

  throw new Error(
    "requestRefresh must either return a string or an object with an accessToken"
  )
}

/**
 * Function that returns an Axios Interceptor that:
 * - Applies that right auth header to requests
 * - Refreshes the access token when needed
 * - Puts subsequent requests in a queue and executes them in order after the access token has been refreshed.
 *
 * @param {AuthTokenInterceptorConfig} config - Configuration for the interceptor
 * @returns {Promise<AxiosRequestConfig} Promise that resolves in the supplied requestConfig
 */
export const authTokenInterceptor = ({
  header = "Authorization",
  headerPrefix = "Bearer ",
  requestRefresh
}) => async requestConfig => {
  // We need refresh token to do any authenticated requests
  const refreshToken = await getRefreshToken()
  if (!refreshToken) return requestConfig

  const authenticateRequest = token => {
    if (token) {
      requestConfig.headers = requestConfig.headers ?? {}
      requestConfig.headers[header] = `${headerPrefix}${token}`
    }
    return requestConfig
  }

  // Queue the request if another refresh request is currently happening
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      queue.push({ resolve, reject })
    }).then(authenticateRequest)
  }

  // Do refresh if needed
  let accessToken
  try {
    setIsRefreshing(true)
    accessToken = await refreshTokenIfNeeded(requestRefresh)
  } catch (error) {
    declineQueue(error)

    if (error instanceof Error) {
      error.message = `Unable to refresh access token for request due to token refresh error: ${error.message}`
    }

    throw error
  } finally {
    setIsRefreshing(false)
  }
  resolveQueue(accessToken)

  // add token to headers
  return authenticateRequest(accessToken)
}

let isRefreshing = false
let queue = []

/**
 * Check if tokens are currently being refreshed
 *
 * @returns {boolean} True if the tokens are currently being refreshed, false is not
 */
export function getIsRefreshing() {
  return isRefreshing
}

/**
 * Update refresh state
 *
 * @param {boolean} newRefreshingState
 */
export function setIsRefreshing(newRefreshingState) {
  isRefreshing = newRefreshingState
}

/**
 * Function that resolves all items in the queue with the provided token
 * @param token New access token
 */
const resolveQueue = token => {
  queue.forEach(p => {
    p.resolve(token)
  })

  queue = []
}

/**
 * Function that declines all items in the queue with the provided error
 * @param error Error
 */
const declineQueue = error => {
  queue.forEach(p => {
    p.reject(error)
  })

  queue = []
}
