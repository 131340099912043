import axios from 'axios';
import { loginApple, loginGoogle, logout } from './login';
import { trackStream } from './stream';
import {
  getAnalytics,
  getUser,
  getUserById,
  getUserByUsername,
  getCommunity,
  getTopLocations,
  getByGender,
  getByMonths,
  getEmailContent,
} from './users';

export const API_CALLS = {
  LOGIN_WITH_GOOGLE: 'api_google',
  LOGIN_WITH_APPLE: 'api_apple',
  LOGOUT: 'api_logout',

  GET_USERS: 'api_users',
  GET_PROFILE_BY_USERNAME: 'api_profile_username',
  GET_PROFILE: 'api_profile',
  UPDATE_PROFILE: 'api_profile_update',
  DELETE_USER: 'api_delete_user',

  GET_BLOGS: 'api_blogs',
  GET_BLOG: 'api_blog',

  GET_CONTENTS: 'api_contents',
  GET_CONTENT: 'api_content',
  POST_CONTENT: 'api_create_content',
  SHARE_CONTENT: 'api_share_content',
  DELETE_CONTENT: 'api_delete_content',

  GET_ANALYTICS: 'api_analytics',
  GET_COMMUNITY: 'api_community',
  GET_TOP_LOCATIONS: 'api_top_locations',
  GET_GENDERS: 'api_genders',
  GET_MONTHS: 'api_months',
  GET_EMAIL_CONTENT: 'api_email_content',

  GET_AVATAR_UPLOAD_URL: 'api_avatar_upload',
  GET_CONTENT_UPLOAD_URL: 'api_content_upload',

  GET_PLANS: 'api_get_plans',
  CREATE_PLANS: 'api_create_plans',
  UPDATE_PLANS: 'api_update_plans',
  GET_SUBS_DETAILS: 'api_get_subs_details',
  CANCEL_SUBS: 'api_cancel_subs',

  REPORT: 'api_report',
  TRACK_STREAM: 'api_track_stream',

  GET_NOTIFICATIONS: 'api_get_notifications',
  GET_PLAYER_TOKEN: 'api_player_token',

  GET_ALBUMS: 'api_get_albums',
};

export const callAPI = async (
  type,
  param,
  downloadProgress,
) => {
  try {
    switch (type) {
      case API_CALLS.LOGIN_WITH_GOOGLE:
        return await loginGoogle(param);
      case API_CALLS.LOGIN_WITH_APPLE:
        return await loginApple(param);
      case API_CALLS.GET_USERS:
        return await getUser(param);
      case API_CALLS.GET_PROFILE:
        return await getUserById(param);
      case API_CALLS.GET_PROFILE_BY_USERNAME:
        return await getUserByUsername(param);
      case API_CALLS.GET_CONTENTS:
        return await getContents(param);
      case API_CALLS.GET_CONTENT:
        return await getContentById(param);
      case API_CALLS.DELETE_CONTENT:
        return await deleteContentById(param);
      case API_CALLS.LOGOUT:
        return await logout();
      case API_CALLS.GET_ANALYTICS:
        return await getAnalytics(param);
      case API_CALLS.GET_COMMUNITY:
        return await getCommunity(param);
      case API_CALLS.GET_TOP_LOCATIONS:
        return await getTopLocations(param);
      case API_CALLS.GET_GENDERS:
        return await getByGender(param);
      case API_CALLS.GET_MONTHS:
        return await getByMonths(param);
      case API_CALLS.TRACK_STREAM:
        return await trackStream(param);
      case API_CALLS.GET_EMAIL_CONTENT:
        return await getEmailContent(param);
    }
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    throw error;
  }
};
