import {
  BASE_URL,
  ROUTES,
  applyAuthTokenInterceptor,
} from 'api/http-common';
import axios from 'axios';

export const axiosInstance = axios.create({ baseURL: BASE_URL.PROD });

const requestRefresh = async (
  refreshToken,
) => {
  // Important! Do NOT use the axios instance that you supplied to applyAuthTokenInterceptor
  // because this will result in an infinite loop when trying to refresh the token.
  // Use the global axios client or a different instance
  const response = await axios.post(`${BASE_URL.PROD}${ROUTES.REFRESH_TOKEN}`, {
    refreshToken,
  });

  return response.data.access_token;
};

applyAuthTokenInterceptor(axiosInstance, { requestRefresh });
