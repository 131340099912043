/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/** 
  All of the routes for the Vision UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Vision UI Dashboard React layouts
import ComingSoon from "layouts/comingsoon";
import Dashboard from "layouts/dashboard";
import EmailSender from "layouts/emailsender";
import { FaUpload, FaHeart } from "react-icons/fa";
import { CgCommunity } from "react-icons/cg";

// Vision UI Dashboard React icons
import { IoAnalytics, IoSettings } from "react-icons/io5";

const routes = [
  {
    type: "collapse",
    name: "Analytics",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoAnalytics size="15px" color="white" />,
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Fan Connect",
    key: "fans",
    beta: true,
    route: "/fans",
    icon: <FaHeart size="15px" color="white" />,
    component: EmailSender,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "My Community",
    key: "community",
    beta: true,
    route: "/community",
    icon: <CgCommunity size="15px" color="white" />,
    component: ComingSoon,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Upload Release",
    key: "release",
    soon: true,
    route: "/release",
    icon: <FaUpload size="15px" color="white" />,
    component: ComingSoon,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Your App",
    key: "settings",
    soon: true,
    route: "/settings",
    icon: <IoSettings size="15px" color="white" />,
    component: ComingSoon,
    noCollapse: true,
  }
];

export default routes;
