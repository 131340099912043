import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import comingsoon from 'assets/images/coming-soon.webp';

function ComingSoon() {
    return (
        <DashboardLayout>
            <DashboardNavbar avatar={localStorage.getItem('avatar')} />
                <VuiBox right={0} top={0} left="15%" height="100%" position="fixed" display="flex" justifyContent="center" alignItems='center'>
                <VuiBox
                    component="img"
                    src={comingsoon}
                    mb="8px"
                    borderRadius="15px"
                    sx={({ breakpoints }) => ({
                    [breakpoints.up("xl")]: {
                        height: "200px",
                    },
                    })}
                />
                </VuiBox>
        </DashboardLayout>
    );
};

export default ComingSoon;
