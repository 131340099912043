import axios from 'axios';

import { axiosInstance } from './api';
import { ROUTES } from './http-common';

export const getUser = async (param) => {
  const response = await axiosInstance.get(
    ROUTES.USERS.replace('{filters}', param),
  );
  return response.data;
};

export const getUserById = async (userId) => {
  const response = await axiosInstance.get(
    ROUTES.PROFILE.replace('{userId}', userId),
  );
  return response.data;
};

export const getUserByUsername = async (username) => {
  const response = await axios.get(
    ROUTES.PROFILE_BY_USERNAME.replace('{username}', username),
  );
  return response.data;
};

export const getAnalytics = async (param) => {
  const response = await axiosInstance.get(
    ROUTES.ANALYTICS.replace('{filters}', param),
  );
  return response.data;
};

export const getCommunity = async (param) => {
  const response = await axiosInstance.get(
    ROUTES.COMMUNITY.replace('{filters}', param),
  );
  return response.data;
};

export const getTopLocations = async (param) => {
  const response = await axiosInstance.get(
    ROUTES.TOP_LOCATIONS.replace('{filters}', param),
  );
  return response.data;
};

export const getByGender = async (param) => {
  const response = await axiosInstance.get(
    ROUTES.GET_GENDERS.replace('{filters}', param),
  );
  return response.data;
};

export const getByMonths = async (param) => {
  const response = await axiosInstance.get(
    ROUTES.GET_MONTHS.replace('{filters}', param),
  );
  return response.data;
};

export const getEmailContent = async (param) => {
  const response = await axiosInstance.post(ROUTES.GET_EMAIL_CONTENT, param);
  return response.data;
};